import consumer from "./consumer"

const messageChannel = consumer.subscriptions.create("UserMessagesChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log('message received:' + data.message);
    const UserShared = require('../../user/shared.js.erb');
    UserShared.message_received(data);
  }

});


