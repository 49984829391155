

class UserEstimates {
    static filter() {
        $('.btn-show-filter').on('click', function() {
            console.log($(this).hasClass('collapsed'));
            let historyURL = new URL(window.location);
            historyURL.searchParams.set('show_filter', $(this).hasClass('collapsed'));
            history.pushState(null, null, historyURL.toString());
            $('#form-estimate-filter input.hidden-show-filter').val($(this).hasClass('collapsed'));
        });
        $('.btn-search-clear').on('click', function() {
            $('#form-estimate-filter .search-inputs').not('.category').val('');
            let $fieldCategory = $('#category_id');
            let categoryId = $fieldCategory.data('submenu-category-id');
            $fieldCategory.val(categoryId);
            $('.only-fixed-checkbox').removeAttr("checked").prop("checked", false);
        });
    }
    // 右固定エリア内のスクロール
    static scroll_prices() {
        let headerHeight = parseInt($('.app-body').css('margin-top'));
        let priceContentsWrapper = $('.price-contents-wrapper');
        // priceContentsWrapperの高さを画面高さに設定してスクロールバーが表示されるようにする
        $(document).ready(function () {
            let height = $(window).height() - headerHeight;
            priceContentsWrapper.css("height", height + "px");
        });
        $(window).resize(function () {
            let height = $(window).height() - headerHeight;
            priceContentsWrapper.css("height", height + "px");
        });
    }
    // 合計金額の固定
    static fixed_prices() {
        let headerHeight = parseInt($('.app-body').css('margin-top'));
        let stickyStop = $('#stickyStop');
        let stickyStopTop = stickyStop.offset().top;
        let priceContentsWrapper = $('.price-contents-wrapper');
        $(window).on('scroll resize', function(){
            let scrollTop = $(document).scrollTop();
            if (scrollTop> (stickyStopTop - headerHeight) ) {
                priceContentsWrapper.css({'position': 'fixed', 'top': headerHeight, 'right': 0});
            } else if (scrollTop <= stickyStopTop) {
                priceContentsWrapper.css({'position': 'absolute', 'top': 0, 'right': 0});
            }
        });
    }
    static cloneProcessingForm($content, selectedId, baseId) {
        let nextIndex = $content.find('.field-container>div').length
        //テンプレートはindex:9999で作成しているので正しいindexに書き換える
        let htmlText = $content.find('.field-template').html().replace(/9999/g, nextIndex.toString()).trim();
        let $clone = $($.parseHTML(htmlText)[0]);
        $clone.find('input, select').prop('disabled', false);
        $clone.find('input.hidden-processing').prop('disabled', true);
        //依存で作成されたものはbase-idに親UUIDを持つ
        if(baseId){
            $clone.data('base-id', baseId);
            $clone.find('.button-delete').toggle(false);
            $clone.find('select').prop('disabled', true);
            if(selectedId) {
                $clone.find('input.hidden-processing').val(selectedId).prop('disabled', false);
            }
        }
        //通常は自身のUUIDを持つ
        else {
            $clone.data('uuid', Quote.shared.uuid());
        }
        $content.find('.field-container').append($clone);
        if(selectedId) {
            $clone.find('select').val(selectedId.toString());
        }
    }

    /**
     * DIO共通 width <= height の法則
     */
    static dio_width() {
        $('.form-estimate').on('change', '.field-width', function() {
            widthHeightCheck();
        });
        $('.form-estimate').on('change', '.field-height', function() {
            widthHeightCheck();
        });
        function widthHeightCheck(){
            let widthValue = $('.form-estimate .field-width').val();
            let heightValue = $('.form-estimate .field-height').val();
            if (widthValue == '' || heightValue == ''){ return }
            let width = parseFloat(widthValue);
            let height = parseFloat(heightValue);
            let $target = $('.form-estimate').find('.field-estimate_item-height');
            Quote.shared.hide_validation_errors($target);
            if (width > height) {
                Quote.shared.show_validation_errors($target, "長さは幅よりも大きな値を設定してください。");
            }
        }
    }
    /**
     * DIO加工用
     */
    static dio_processing() {
        $('.form-estimate').on('click', '.button-add', function () {
            UserEstimates.cloneProcessingForm($(this).closest('.content-position'));
            return false;
        });
        $('.form-estimate').on('click', '.button-delete', function () {
            let $field = $(this).closest('.content-processing');
            let baseId = $field.data('uuid');
            let $container = $(this).closest('.field-container');
            UserEstimates.deleteDependencies($container, baseId);
            $field.remove();
            $('.calculate-trigger').first().trigger('change');
            return false;
        });
        $('.form-estimate').on('change', '.select-processing', function () {
            let unitTitle = $(this).find('option:selected').data('unit-title') || '';
            let unitLabel = $(this).find('option:selected').data('unit') || '';
            let unitStep = $(this).find('option:selected').data('unit-step') || '1';
            let dependentIds = $(this).find('option:selected').data('dependents') || [];
            let baseId = $(this).closest('.content-processing').data('uuid');
            let $container = $(this).closest('.field-container');
            UserEstimates.deleteDependencies($container, baseId);
            if (dependentIds) {
                $.each(UserEstimates.missingDependencies(dependentIds, $container), function (index, dependentId) {
                    UserEstimates.cloneProcessingForm($container.closest('.content-position'), dependentId, baseId);
                })
            }
            let $content = $(this).closest('.content-processing');
            let $unitTitle = $content.find('.label-unit-title');
            let $unitField = $content.find('.field-unit-label');
            let $unitLabel = $unitField.find('.input-group-append>span');
            if (unitTitle) {
                $unitField.show().find('input').attr('disabled', false).attr('step', unitStep);
            } else {
                $unitField.hide().find('input').attr('disabled', true);
            }
            $unitTitle.text(unitTitle);
            $unitLabel.text(unitLabel);
        });
    }
    static dio_form() {
        $('.form-estimate').on('change keyup', '.calculate-trigger', function() {
            let $form = $('.form-estimate');
            let calc_url = "/api/estimates/calc_dio?product_id={product_id}&user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{product_id}', $form.find('input[name=product_id]').val()).replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populate($form, result);
                }
            })
            return false;
        })
        function populate($form, result){
            UserEstimates.populatePosition($form, result, 'top');
            UserEstimates.populatePosition($form, result, 'bottom');
            UserEstimates.populatePosition($form, result, 'left');
            UserEstimates.populatePosition($form, result, 'right');
            UserEstimates.populateYield($form, result);
            UserEstimates.populateSum($form, result);
        }
    }
    static dio_ball_form() {
        $('.form-estimate').on('change keyup', '.calculate-trigger', function() {
            let $form = $('.form-estimate');
            let calc_url = "/api/estimates/calc_dio_ball?product_id={product_id}&user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{product_id}', $form.find('input[name=product_id]').val()).replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populate($form, result);
                }
            })
            return false;
        })
        function populate($form, result){
            let $widthField = $('.form-estimate .field-estimate_item-width');
            Quote.shared.hide_validation_errors($widthField);
            if(result.yield && !result.yield.price){
                Quote.shared.show_validation_errors($widthField, "価格表に価格がありません。");
            }
            let $heightField = $('.form-estimate .field-estimate_item-height');
            if(!$heightField.hasClass('error')) {
                Quote.shared.hide_validation_errors($heightField);
                if(result.yield && !result.yield.price){
                    Quote.shared.show_validation_errors($heightField, "価格表に価格がありません。");
                }
            }
            UserEstimates.populateYield($form, result);
            UserEstimates.populateSum($form, result);
        }
    }
    static dio_bug_form() {
        $('.form-estimate').on('change keyup', '.calculate-trigger', function() {
            let $form = $('.form-estimate');
            let calc_url = "/api/estimates/calc_dio_bug?product_id={product_id}&user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{product_id}', $form.find('input[name=product_id]').val()).replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populate($form, result);
                }
            })
            return false;
        })
        function populate($form, result){
            UserEstimates.populateYield($form, result);
            UserEstimates.populateSum($form, result);
        }
    }
    static dio_crow_form() {
        $('.form-estimate').on('change keyup', '.calculate-trigger', function() {
            let $form = $('.form-estimate');
            let calc_url = "/api/estimates/calc_dio_crow?product_id={product_id}&user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{product_id}', $form.find('input[name=product_id]').val()).replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populate($form, result);
                }
            })
            return false;
        })
        function populate($form, result){
            UserEstimates.populateYield($form, result);
            UserEstimates.populateSum($form, result);
        }
    }
    static dio_fix_form() {
        $('.form-estimate').on('change keyup', '.calculate-trigger', function() {
            let $form = $('.form-estimate');
            let calc_url = "/api/estimates/calc_dio_fix?product_id={product_id}&user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{product_id}', $form.find('input[name=product_id]').val()).replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populate($form, result);
                }
            })
            return false;
        })
        function populate($form, result){
            UserEstimates.populatePosition($form, result, 'top');
            UserEstimates.populatePosition($form, result, 'bottom');
            UserEstimates.populatePosition($form, result, 'left');
            UserEstimates.populatePosition($form, result, 'right');
            UserEstimates.populateYield($form, result);
            UserEstimates.populateSum($form, result);
        }
    }
    static dio_fusion_form() {
        $('.form-estimate').on('change keyup', '.calculate-trigger', function() {
            let $form = $('.form-estimate');
            let calc_url = "/api/estimates/calc_dio_fusion?product_id={product_id}&user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{product_id}', $form.find('input[name=product_id]').val()).replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populate($form, result);
                }
            })
            return false;
        })
        function populate($form, result){
            UserEstimates.populatePosition($form, result, 'top');
            UserEstimates.populatePosition($form, result, 'bottom');
            UserEstimates.populatePosition($form, result, 'left');
            UserEstimates.populatePosition($form, result, 'right');
            UserEstimates.populateYield($form, result);
            UserEstimates.populateSum($form, result);
        }
    }
    static dio_quarter_form() {
        $('.form-estimate').on('change keyup', '.calculate-trigger', function() {
            let $form = $('.form-estimate');
            let calc_url = "/api/estimates/calc_dio_quarter?product_id={product_id}&user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{product_id}', $form.find('input[name=product_id]').val()).replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populate($form, result);
                }
            })
            return false;
        })
        function populate($form, result){
            UserEstimates.populateYield($form, result);
            UserEstimates.populateSum($form, result);
        }
    }

    /**
     * ラジオボタン変更でhidden:product_nameの書き換え、画像更新を行う
     */
    static product_name() {
        $('.form-estimate').on('click', '.radio-product', function() {
            UserEstimates.updateProductId($(this));
            UserEstimates.updateProductImage($(this));
        });
    }
    static updateProductId($radio) {
        let fullName = $radio.data('full-name');
        $('.form-estimate input.field-product_name').val(fullName);
        $('.form-estimate input.hidden-product_id').val($radio.val());
    }
    static updateProductImage($radio) {
        //画像更新
        let $targetContainer = $('.form-estimate').find('.processing-images-container .image-processing-product');
        let $targetInner = $targetContainer.find('.carousel>.carousel-inner');
        $targetInner.empty();
        let imageUrl = $radio.data('image-url');
        let productName = $radio.siblings('div.label').text();
        let $itemTemplate = $targetContainer.first().find('.processing-images-template .carousel-item').clone();
        $itemTemplate.find('.item-image').attr('src', imageUrl);
        $itemTemplate.find('.processing-name').text(productName);
        $itemTemplate.addClass('active');
        $targetContainer.find('.carousel-inner').append($itemTemplate);
    }

    /**
     * 開閉イメージ更新
     */
    static frontage_image() {

        $('.form-estimate').on('change', '.radio-frontage', function() {
            $('.form-estimate .image-frontage').hide();
            let door_type = $('.form-estimate .radio-door_type:checked').val();
            let edge_type = $('.form-estimate .radio-edge_type:checked').val();
            let attach_type = $('.form-estimate .radio-attach_type:checked').val();
            console.log(door_type, edge_type);
            $(`.form-estimate .image-frontage[data-door=${door_type}][data-edge=${edge_type}]`).show();
            $(`.form-estimate .image-frontage[data-attach=${attach_type}]`).show();
        });
    }


    static updateTakasheetHanger(selected) {
        let $container = $('.form-estimate .field-takasheet_hanger-container');
        let $activeField;
        if (selected) { // デフォルト選択に戻す
            $container.find('.field-option').each(function (index, element) {
                let $field = $(element).find('input.field-processing-amount');
                let defaultAmount = parseInt($field.data('default-amount'));
                if (defaultAmount > 0) {
                    UserEstimates.populateRadioField($(element), false, $('.form-estimate .field-amount_by_sheet').val());
                    $(element).find('input[type="radio"]').prop('checked', true);
                    $activeField = $field;
                } else {
                    UserEstimates.populateRadioField($(element), true, 0);
                }
            });
        }
        else { // unselectedを選択する
            $container.find('.field-option').each(function (index, element) {
                let $field = $(element).find('input.field-processing-amount');
                if ($field.data('unselected')) {
                    UserEstimates.populateRadioField($(element), false, $('.form-estimate .field-amount_by_sheet').val());
                    $(element).find('input[type="radio"]').prop('checked', true);
                    $activeField = $field;
                } else {
                    UserEstimates.populateRadioField($(element), true, 0);
                }
            });
        }
        return $activeField;
    }

    static updateTakasheetFrame(selected) {
        let $container = $('.form-estimate .field-takasheet_frame-container');
        let $activeField;
        if (selected) {
            $container.find('.field-option').each(function (index, element) {
                let $amountField = $(element).find('input.field-processing-amount');
                let value = parseInt($amountField.data('default-amount'));
                $(element).find("input[type='radio']").prop('checked', value > 0);
                UserEstimates.populateRadioField($(element), value == 0, value);
            });
            let $hangerContainer = $('.form-estimate .field-takasheet_hanger-container');
            let $hangerRadio = $hangerContainer.find('input[type="radio"]:checked');
            let pairIds = $hangerRadio.data('pair-ids');
            UserEstimates.activateByHanger(pairIds, false);
            $activeField = $container.find('.field-processing-amount:enabled');
        }
        else {
            UserEstimates.clearFrameFields();
            $container.find('input').prop('disabled', true); //入力欄を活性変更
            $container.find('.field-processing-amount').val(0);
            //価格を更新するために0件をトリガーする
            $activeField = $container.find('.field-processing-amount').first();
        }
        return $activeField;
    }

    static clearFrameFields() {
        let $form = $('.form-estimate');
        $form.find('.field-frame-processing_id').val('');
        $form.find('.field-frame-amount').val('');
        $form.find('.field-frame-price').val('');
        $form.find('.field-frame-total').val('');
    }

    static activateByHanger(pairIds, withSelect) {
        if(!pairIds) return;
        let frameSelected = $('input[name="form[processing_group][add_frame]"]:checked').val() == 'true';
        if(!frameSelected) return;
        let hit = false
        pairIds.forEach(function(pairId) {
            let $hidden = $('.field-takasheet_frame-container').find('input[value="' + pairId + '"]');
            if ($hidden.length > 0) {
                let $hitField = $hidden.closest('.field-option');
                let $radioField = $hitField.find('input[type=radio]');
                UserEstimates.populateRadioField($hitField, !$radioField.prop('checked'));
                $radioField.prop('disabled', false);
                if(!hit && withSelect) $radioField.prop('checked', true).trigger('change');
                hit = true;
            }
        });
    }

    static takasheet_form() {
        $('.form-estimate').on('change', '.field-frontage_width,.field-frontage_height,.field-width,.field-height', function() {
            Quote.shared_estimates.size_check($(this));
        });
        /**
         * オプション詳細のラジオ選択
         */
        $('.form-estimate').on('change', '.radio-processing', function() {
            $(this).closest('.field-options').find('.field-option').each(function (index, element) {
                UserEstimates.populateRadioField($(element), true, 0);
            });
            let $amountField = $(this).closest('.field-option').find('.field-processing-amount')
            //右パネル上の個数表示欄（個数を維持するために使用）
            let $sourceField;
            if ($(this).closest('.field-options').hasClass('field-takasheet_hanger-container')){
                $sourceField = $('.form-estimate .field-amount_by_sheet');
                //ハンガーなしを選択した場合、「購入なし」にする必要がある。
                if ($amountField.data('unselected'))
                    $('input[name="form[processing_group][add_hanger]"]').val(["false"])
                else
                    $('input[name="form[processing_group][add_hanger]"]').val(["true"])
            }
            else {
                $sourceField = $('.form-estimate .field-num_frames');
            }
            //不活性ボタンがthisになる場合もある（価格を「０」にするために）
            if(!$(this).prop('disabled'))
                UserEstimates.populateRadioField($(this).closest('.field-option'), false, $sourceField.val());

            let $container = $(this).closest('.option-container');
            UserEstimates.populateOptions($container);
            //トータル金額再計算
            $('.form-estimate .calculate-trigger').first().trigger('change');
        });
        /**
         * 右側のハンガー数を変更
         */
        $('.form-estimate').on('change', '.field-amount_by_sheet', function() {
            //オプション詳細で選択されているハンガーの個数を変更
            let $target = $('input[name="form[estimate_processing][takasheet_hanger][][amount]"]:enabled');
            $target.val($(this).val());
            //PC用スマホ用２箇所あるため、両方の数値を揃える必要がある。
            $('.form-estimate .field-amount_by_sheet').val($(this).val());
            //トータル金額再計算
            $('.form-estimate .calculate-trigger').first().trigger('change');
        });
        $('.field-takasheet_hanger-container').on('change', '.field-processing-amount', function(e, skipCalc) {
            $('.form-estimate .field-amount_by_sheet').val($(this).val());
            if(!skipCalc)
                $('.form-estimate .calculate-trigger').first().trigger('change');
        });
        $('.field-takasheet_frame-container').on('change', '.field-processing-amount', function(e, skipCalc) {
            $('.form-estimate .field-num_frames').val($(this).val());
            if(!skipCalc)
                $('.form-estimate .calculate-trigger').first().trigger('change');
        });
        $('.form-processing_group-hanger').on('change', '.add-processing-group', function(e, skipCalc) {
            let selected = $(this).val() == 'true';
            let $activeField = UserEstimates.updateTakasheetHanger(selected);
            if($activeField) {
                $activeField.trigger('change', [skipCalc]);
                let $field = $activeField.closest('.field-option');
                $field.find('input[type="radio"]').trigger('change');
            }
        });
        $('.form-processing_group-frame').on('change', '.add-processing-group', function(e, skipCalc) {
            let selected = $(this).val() == 'true';
            let $activeField = UserEstimates.updateTakasheetFrame(selected);
            if($activeField) {
                $activeField.trigger('change', [skipCalc]);

                let $hangerContainer = $('.form-estimate .field-takasheet_hanger-container');
                let $hangerRadio = $hangerContainer.find('input[type="radio"]:checked');
                let pairIds = $hangerRadio.data('pair-ids');
                UserEstimates.activateByHanger(pairIds, true);
            }
        });
        $('.field-takasheet_hanger-container').on('change', 'input[type=radio]', function() {
            let pairIds = $(this).data('pair-ids');
            console.log("pairIds:", pairIds);
            if(!pairIds) return;
            $('.field-takasheet_frame-container .field-option').each(function (index, element) {
                UserEstimates.populateRadioField($(element), true, 0);
                let $radioField = $(element).find('input[type=radio]');
                $radioField.prop('disabled', true);
            });
            UserEstimates.activateByHanger(pairIds, true);
        });
        // takasheet
        $('.form-estimate').on('change', '.choose-specification-trigger', function() {
            let $form = $('.form-estimate');
            // フォーム左の画像選択ボタンのURLを更新
//            Quote.user_estimates.updateImageUrl($form);
            let calc_url = "/api/estimates/choose_takasheet_specification?user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populateSpecification($form, result);
                    $('.choose-processings-trigger').first().trigger('change');
                }
            })
            return false;
        })
        // takasheet
        $('.form-estimate').on('change', '.choose-processings-trigger', function() {
            let $form = $('.form-estimate');
            // フォーム左の画像選択ボタンのURLを更新
//            Quote.user_estimates.updateImageUrl($form);
            let calc_url = "/api/estimates/choose_takasheet_processings?user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }

                    let hangerSelected = $('input[name="form[processing_group][add_hanger]"]:checked').val() == 'true';
                    let frameSelected = $('input[name="form[processing_group][add_frame]"]:checked').val() == 'true';
                    populateTakasheetOptions($form, result);

                    //ハンガーは０にしない、シート数のカウントに関係するから
                    let hangerCount = getDefaultAmount(result, 'takasheet_hanger', true);
                    $('.form-estimate .field-amount_by_sheet').prop('disabled', hangerCount == 0).val(hangerCount);
                    let frameCount = getDefaultAmount(result, 'takasheet_frame', frameSelected);
                    $('.form-estimate .field-num_frames').val(frameCount);
                    //画面右の小計、重さ、画像を更新
                    $('.field-processing-amount:enabled').each(function (index, element) {
                        UserEstimates.processingOption($(element));
                    });
                    let $activeHanger = Quote.user_estimates.updateTakasheetHanger(hangerSelected);
                    let $activeFrame = Quote.user_estimates.updateTakasheetFrame(frameSelected);
                    if($activeHanger) {
                        //$activeHanger.trigger('change');
                        $activeHanger.closest('.field-option').find('input[type=radio]').trigger('change');
                    }
                    if($activeFrame) {
                        //$activeFrame.trigger('change', [$activeHanger]);
                        $activeFrame.closest('.field-option').find('input[type=radio]').trigger('change');
                    } // skipCalc: calcを１回で済ます
                }
            })
            return false;
        });
        function populateTakasheetOptions($form, result){
            //フィールドを作成、１つも作成されなかった場合はfalseが返る
            if(!UserEstimates.populateDefaultOptions($form, result))
                return;
            $.each(result.processings, function(key, processings) {
                //console.log(key, processings);
                let $container = $form.find(`.field-${key}-container`);
                // radioボタンの調整
                $.each(processings, function(index, processing) {
                    let $fieldContainer = $($container.find('.field-option')[index]);
                    let $radioButton = $fieldContainer.find('.radio-processing')
                    $radioButton.attr('id', `processing-${processing.processing_id}`)
                      .attr('name', `form[${key}][processing_id]`)
                      .attr('value', processing.processing_id)
                      .prop('checked', processing.default_amount > 0);
                    UserEstimates.populateRadioField($fieldContainer, processing.default_amount == 0)
                    $fieldContainer.find('.label-processing-name').attr('for', `processing-${processing.processing_id}`);
                });
            });
        }
        function getDefaultAmount(result, code, selected){
            if (!selected || !result || !result.processings || !result.processings[code]) return 0;
            let count = 0;
            $.each(result.processings[code], function (index, param) {
                if (param.default_amount > 0)
                    count = param.default_amount;
            });
            return count;
        }
        // takasheet
        $('.form-estimate').on('change', '.calculate-trigger', function() {
            let $form = $('.form-estimate');
            let $hidden_product_id = $('.form-estimate .hidden-product_id');
            let productId = $form.find('input[name="form[estimate_item][product_id]"]:checked').val();
            $hidden_product_id.val(productId);
            Quote.user_estimates.updateImageUrl($form);
            let calc_url = "/api/estimates/calc_takasheet?product_id={product_id}&user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{product_id}', productId).replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populate($form, result);
                }
            })
            return false;
        })

        function populateSpecification($form, result){
            Quote.shared_estimates.populateProductSize($form, result);
            UserEstimates.populateRecommends($form, result);
            //$form.find("input[name='form[processing][hanger_width]']").val([result.processing_aurora_hanger_width]);
            //$form.find('.field-aurora-lap').val([result.processing_aurora_lap_id]);
            //$form.find('.field-material').val([result.material]);
        }

        function populate($form, result){
            let hangerSelected = $('input[name="form[processing_group][add_hanger]"]:checked').val() == 'true';
            let frameSelected = $('input[name="form[processing_group][add_frame]"]:checked').val() == 'true';
            if(result.frame && result.frame.count && frameSelected) {
                $form.find('.field-frame-processing_id').val(result.frame.processing_id);
                $form.find('.field-frame-amount').val(result.frame.count);
                $form.find('.field-frame-price').val(result.frame.price);
                $form.find('.field-frame-total').val(result.frame.total);
            }
            else {
                UserEstimates.clearFrameFields();
            }

            if(result.hanger && result.hanger.count) {
                $form.find('.field-hanger-processing_id').val(result.hanger.processing_id);
                $form.find('.field-hanger-amount').val(result.hanger.count);
                $form.find('.field-hanger-price').val(result.hanger.price);
                $form.find('.field-hanger-total').val(result.hanger.total);
            }
            else {
                $form.find('.field-hanger-processing_id').val('');
                $form.find('.field-hanger-amount').val('');
                $form.find('.field-hanger-price').val('');
                $form.find('.field-hanger-total').val('');
            }

            if(result.sheet && result.sheet.count) {
                $form.find('.field-sheet-processing_id').val(result.sheet.processing_id);
                $form.find('.field-sheet-amount').val(result.sheet.count);
                $form.find('.field-sheet-price').val(result.sheet.price);
                $form.find('.field-sheet-total').val(result.sheet.total);
            }
            else {
                $form.find('.field-sheet-processing_id').val('');
                $form.find('.field-sheet-amount').val('');
                $form.find('.field-sheet-price').val('');
                $form.find('.field-sheet-total').val('');
            }

            if(result.yield && result.yield.count) {
                $form.find('.field-yield-amount').val(result.yield.count);
                $form.find('.field-yield-height').val(result.yield.height);
                $form.find('.field-yield-price').val(result.yield.price);
                $form.find('.field-yield-total').val(result.yield.total);
                $form.find('.field-amount_by_sheet').val(result.yield.count);
            }
            else {
                $form.find('.field-yield-amount').val('');
                $form.find('.field-yield-height').val('');
                $form.find('.field-yield-price').val('');
                $form.find('.field-yield-total').val('');
                $form.find('.field-amount_by_sheet').val('');
            }
            UserEstimates.populateSum($form, result);
            //小計更新
            if(result.sum && result.sum.processing_by_sheet) {
                $('.option-container[data-type="takasheet_hanger"] .label-subtotal-price').text(Quote.shared.numberWithCommas(result.sum.processing_by_sheet));
            }

            $('.label-processing-price').text(Quote.shared.numberWithCommas(result.sum.estimate_subtotal / result.amount));
        }
    }
    static aurora_form() {
        $('.form-estimate').on('change', '.field-frontage_width,.field-frontage_height,.field-width,.field-height', function() {
            Quote.shared_estimates.size_check($(this));
        });

        $('.form-estimate').on('change', '.choose-specification-trigger', function() {
            let $form = $('.form-estimate');
            // フォーム左の画像選択ボタンのURLを更新
//            Quote.user_estimates.updateImageUrl($form);
            let calc_url = "/api/estimates/choose_aurora_specification?user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populateSpecification($form, result);
                    $('.choose-processings-trigger').first().trigger('change');
                }
            })
            return false;
        })
        //aurora
        $('.form-estimate').on('change', '.choose-processings-trigger', function() {
            let $form = $('.form-estimate');
            // フォーム左の画像選択ボタンのURLを更新
//            Quote.user_estimates.updateImageUrl($form);
            let calc_url = "/api/estimates/choose_aurora_processings?user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    Quote.shared_estimates.populateProductSize($form, result);

                    let $container_hanger = $form.find(`.field-aurora_hanger-container`);
                    $container_hanger.empty();
                    let key = 'aurora_hanger';
                    if(result.processings && result.processings.aurora_hanger) {
                        $.each(result.processings.aurora_hanger, function (index, processing) {
                            let $field = $form.find(`.form-processing-hanger-template`).children().clone(true,true);
                            UserEstimates.populateOptionField($field, key, processing, 'form_blank'); //
                            //Radioボタン処理
                            $field.find('.radio-processing').attr('id', `processing-${processing.processing_id}`)
                              .attr('name', `form[${key}][processing_id]`).attr('value', processing.processing_id).prop('checked', processing.amount > 0);
                            $field.find('.label-processing-name').attr('for', `processing-${processing.processing_id}`);
                            UserEstimates.populateRadioField($field, processing.amount == 0, processing.amount)
                            $container_hanger.append($field);

                        });
                        delete result.processings.aurora_hanger;
                    }
                    UserEstimates.populateDefaultOptions($form, result);
                    Quote.shared_estimates.calcRunnerByPole(true);
                    //一旦calcの中身は空にする必要がある。
                    populateCalc($form, {});
                    //$('.calculate-trigger').first().trigger('change');
                }
            })
            return false;
        })
        /**
         * aurora
         * オプション詳細のラジオ選択
         */
        $('.form-estimate').on('change', '.radio-processing', function() {
            $(this).closest('.field-options').find('.field-option').each(function (index, element) {
                UserEstimates.populateRadioField($(element), true, 0);
            });
            let $amountField = $(this).closest('.field-option').find('.field-processing-amount')
            //右パネル上の個数表示欄（個数を維持するために使用）
            let $sourceField = $('.form-estimate .field-hanger-amount');
            UserEstimates.populateRadioField($(this).closest('.field-option'), false, $sourceField.val());

            let $container = $(this).closest('.option-container');
            UserEstimates.populateOptions($container);
            //トータル金額再計算
            $('.form-estimate .calculate-trigger').first().trigger('change');
        });
        //ハンガー数変更
        $('.form-estimate').on('change', '.field-aurora_hanger-container .field-processing-amount', function() {
            let prevValue = $('.form-estimate .field-hanger-amount').val();
            let $activeRunner = null;
            $('.field-runner-container').find('.field-processing-amount').each(function (index, element) {
                if($(element).val() == prevValue) $activeRunner = $(element);
            });
            // 同数のrunnerがある場合はこちらも変更する
            // ※runnerを変えてもハンガーは変えない
            if($activeRunner) {
                $activeRunner.val($(this).val()).trigger('change');
            }

            $('.form-estimate').find('.field-hanger-amount').val($(this).val());

            //トータル金額再計算
            $('.form-estimate .calculate-trigger').first().trigger('change');
        });
        //aurora
        $('.form-estimate').on('change', '.calculate-trigger', function(event) {
            let $form = $('.form-estimate');
            //console.log('form',$form.serialize());
            let calc_url = "/api/estimates/calc_aurora?product_id={product_id}&user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{product_id}', $form.find('input[name=product_id]').val()).replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populateCalc($form, result);
                }
            })
            return false;
        })
        function populateSpecification($form, result){
            Quote.shared_estimates.populateProductSize($form, result);
            UserEstimates.populateRecommends($form, result);
            $form.find("input[name='form[processing][hanger_width]']").val([result.processing_aurora_hanger_width]);
            $form.find('.field-aurora-lap').val([result.processing_aurora_lap_id]);
        }
        function populateCalc($form, result){
            let filled = true;
            if(result.hanger && result.hanger.count) {
                $form.find('.field-hanger-processing_id').val(result.hanger.processing_id);
                $form.find('.field-hanger-amount').val(result.hanger.count);
                $form.find('.field-hanger-price').val(result.hanger.price);
                $form.find('.field-hanger-total').val(result.hanger.total);
            }
            else {
                filled = false;
                $form.find('.field-hanger-processing_id').val('');
                $form.find('.field-hanger-amount').val('');
                $form.find('.field-hanger-price').val('');
                $form.find('.field-hanger-total').val('');
            }

            if(result.lap && result.lap.count) {
                $form.find('.field-lap-processing_id').val(result.lap.processing_id);
                $form.find('.field-lap-amount').val(result.lap.count);
                $form.find('.field-lap-price').val(result.lap.price);
                $form.find('.field-lap-total').val(result.lap.total);
            }
            else {
                filled = false;
                $form.find('.field-lap-processing_id').val('');
                $form.find('.field-lap-amount').val('');
                $form.find('.field-lap-price').val('');
                $form.find('.field-lap-total').val('');
            }

            if(result.yield && result.yield.count) {
                $form.find('.field-yield-amount').val(result.yield.count);
                $form.find('.field-yield-height').val(result.yield.height);
                $form.find('.field-yield-price').val(result.yield.price);
                $form.find('.field-yield-total').val(result.yield.total);
                $form.find('.field-amount_by_sheet').val(result.yield.count);
            }
            else {
                filled = false;
                $form.find('.field-yield-amount').val('');
                $form.find('.field-yield-height').val('');
                $form.find('.field-yield-price').val('');
                $form.find('.field-yield-total').val('');
                $form.find('.field-amount_by_sheet').val('');
            }
            if(!filled) {
                delete result.sum;
            }
            UserEstimates.populateSum($form, result);
        }
    }
    // aurora/takasheetのラジオボタン付きフィールドに値を詰める
    static populateRadioField($fieldContainer, disabled, defaultValue){
        $fieldContainer.find("input[type='number']").prop('disabled', disabled);
        $fieldContainer.find("input[type='hidden']").prop('disabled', disabled);
        //$fieldContainer.find("input[type='radio']").prop('disabled', false); // radioは常にアクティブ
        if (defaultValue != null) $fieldContainer.find("input[type='number']").val(defaultValue);
    }

    static ichimura_form() {
        $('.form-estimate').on('change', '.field-frontage_width,.field-frontage_height,.field-width,.field-height', function() {
            Quote.shared_estimates.size_check($(this));
        });
        // 材質選択フォーム表示切り替え
        $('.form-estimate').on('change', '.field-specification', function() {
            let $form = $('.form-estimate');
            let showMaterial = $(this).data('show-material');
            if (showMaterial) {
                $form.find('.field-material').prop('disabled', false);
                $form.find('.form-material').show();
                //シート加工タブの更新
                let $checkedMaterial = $('.form-estimate .field-material:checked');
                $checkedMaterial.first().trigger('change');
            } else {
                $form.find('.field-material').prop('disabled', true);
                $form.find('.form-material').hide();
                // シート加工タブの更新（xg、sgは、標準仕様に）
                let $checkedMaterial = $form.find('input.field-material[value="aluminum"]');
                $checkedMaterial.first().trigger('change');
            }
        });
        // シート加工タブの更新（例：標準仕様ステン）
        $('.form-estimate').on('change', '.field-material', function() {
            let $form = $('.form-estimate');
            // カスタム加工がアクティブの場合はそのまま
            if ($form.find('.tab-custom').hasClass('active')) {
                return;
            }
            let material = $(this).val();
            let $tabLink = $form.find(".tab-" + material);
            if ($tabLink.length > 0) {
                let url = new URL(window.location.origin + $tabLink.attr('href'));
                sendTabLink(url);
            } else {
                let url = new URL(window.location.origin + $form.find('.tab-default').attr('href'));
                sendTabLink(url);
            }
            function sendTabLink(url) {
                //URLを書き換える
                history.pushState(null, null, url.toString());
                $.ajax({
                    dataType: 'script',
                    url: url.toString(),
                    success: ()  => {}
                })
            }
        });

        /**
         * ichimura
         * シート加工
         */
        $('.form-estimate').on('change', '.select-processing', function () {
            let dependentIds = $(this).find('option:selected').data('dependents') || [];
            let notices = $(this).find('option:selected').data('notices');
            let unitType = $(this).find('option:selected').data('unit-type');
            let unitSize = $(this).find('option:selected').data('unit-size');
            let baseId = $(this).closest('.content-processing').data('uuid');
            let $container = $(this).closest('.field-container');
            UserEstimates.deleteDependencies($container, baseId);
            if (unitType == 'pitch_mm') {
                $container.find('.hidden-amount-base').val(unitSize);
            } else {
                $container.find('.hidden-amount-base').val('');
            }
            if (dependentIds) {
                $.each(UserEstimates.missingDependencies(dependentIds, $container), function (index, dependentId) {
                    UserEstimates.cloneProcessingForm($container.closest('.content-position'), dependentId, baseId);
                })
            }
        });
        //ichimura
        $('.form-estimate').on('change', '.choose-specification-trigger', function() {
            let $form = $('.form-estimate');
            // フォーム左の画像選択ボタンのURLを更新
//            Quote.user_estimates.updateImageUrl($form);
            let calc_url = "/api/estimates/choose_ichimura_specification?user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populateSpecification($form, result);
                    $('.choose-processings-trigger').first().trigger('change');
                }
            })
            return false;
        })
        //ichimura
        $('.form-estimate').on('change', '.choose-processings-trigger', function() {
            let $form = $('.form-estimate');
            // フォーム左の画像選択ボタンのURLを更新
//            Quote.user_estimates.updateImageUrl($form);
            let calc_url = "/api/estimates/choose_ichimura_processings?user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    Quote.shared_estimates.populateProductSize($form, result);
                    UserEstimates.populateDefaultOptions($form, result);
                    $('.calculate-trigger').first().trigger('change');
                }
            })
            return false;
        })
        /**
         * ichimura
         * resize:trueの時は高さを更新
         */
        $('.form-estimate').on('change', '.calculate-trigger', function(e, resize) {
            let $form = $('.form-estimate');
            // フォーム左の画像選択ボタンのURLを更新
            Quote.user_estimates.updateImageUrl($form);
            let calc_url = "/api/estimates/calc_ichimura?product_id={product_id}&user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{product_id}', $form.find('input[name=product_id]').val()).replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populateCalc($form, result);
                    if (resize) Quote.shared_estimates.populateProductSize($form, result);
                }
            })
            return false;
        })
        function populateSpecification($form, result){
            Quote.shared_estimates.populateProductSize($form, result);
            UserEstimates.populateSpecification($form, result);
            UserEstimates.populateRecommends($form, result);
        }
        function populateCalc($form, result){
            UserEstimates.populatePosition($form, result, 'top');
            UserEstimates.populatePosition($form, result, 'bottom');
            UserEstimates.populatePosition($form, result, 'left');
            UserEstimates.populatePosition($form, result, 'right');
            if(!result.yield) { return }
            $form.find('.field-yield-amount').val(result.yield.amount_by_total);
            $form.find('.field-yield-height').val(result.yield.yield_by_total);
            $form.find('.field-yield-price').val(result.yield.price);
            $form.find('.field-yield-total').val(result.yield.total);
            UserEstimates.populateSum($form, result);
            //シート加工を商品合計に含める
            let productTotal = (result.sum.yield_total || 0) + (result.sum.processing_total || 0)
            $form.find('.label-sum-yield_total').text(Quote.shared.numberWithCommas(productTotal)); //商品合計（右エリア表示）
        }
    }

    static estimate_form() {
        $('.form-estimate').on('change keyup', '.calculate-trigger', function() {
            let $form = $('.form-estimate');
            let calc_url = "/api/estimates/calc_estimate?product_id={product_id}&user_id={user_id}"
            $.ajax({
                url: calc_url.replace('{product_id}', $form.find('input[name=product_id]').val()).replace('{user_id}', $form.find('input[name=user_id]').val()),
                type: 'post',
                data: $form.serialize(),
                success: function(result, textStatus, xhr) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populate($form, result);
                }
            })
            return false;
        })
        function populate($form, result){
            UserEstimates.populatePosition($form, result, 'top');
            UserEstimates.populatePosition($form, result, 'bottom');
            UserEstimates.populatePosition($form, result, 'left');
            UserEstimates.populatePosition($form, result, 'right');
            if(result.yield){
                $form.find('.field-yield-amount').val(result.yield.amount_by_total);
                $form.find('.field-yield-height').val(result.yield.yield_by_total);
                $form.find('.field-yield-price').val(result.yield.price);
                $form.find('.field-yield-total').val(result.yield.total);
            }
            else{
                $form.find('.field-yield-amount').val('');
                $form.find('.field-yield-height').val('');
                $form.find('.field-yield-price').val('');
                $form.find('.field-yield-total').val('');
            }
            UserEstimates.populateSum($form, result);
        }
    }

    /**
     * 部材一式選択エリア（add_rail, add_pole）
     */
    static processing_groups() {
        let $form = $('.form-estimate');
        //部材一式選択「購入する」「購入しない」
        $form.on('change', '.add-processing-group', function(e, skipCalc) {
            let selected = $(this).val() === 'true'
            // checkboxの活性変更
            let $checkboxes = $(this).closest('.form-processing_group').find('.add-processing')
            $checkboxes.prop('disabled', !selected);
            //オプション詳細エリアを更新
            $checkboxes.each(function (index, element) {
                let $container = $form.find(`.field-${$(element).val()}-container`);
                $container.find('input').prop('disabled', !selected); //入力欄を活性変更
                update_option($(element), selected);
            });
            // add-pole-processing-groupの場合は調整後再計算（ここでは再計算しない）
            if(!skipCalc && !$(this).hasClass('add-pole-processing-group'))
                $('.calculate-trigger').first().trigger('change', [true]);
        });
        //チェックボックス選択
        $form.on('change', '.add-processing', function() {
            update_option($(this), true);
            $('.calculate-trigger').first().trigger('change');
        });
        //オプション詳細エリアを更新
        function update_option($input, purchaseSelected) {
            let $container = $form.find(`.field-${$input.val()}-container`);
            if($input.prop('checked') && purchaseSelected) {
                $container.find('.field-processing-amount').each(function (index, element) {
                    $(element).val($(element).data('default-amount'));
                });
            }
            else {
                $container.find('.field-processing-amount').val(0);
            }
            UserEstimates.processingOption($container.find('.field-processing-amount').first());
        }
    }

    /**
     * オプション詳細エリア
     */
    static processing_options() {
        $('.form-estimate').on('change keyup', '.field-processing-amount', function() {
            UserEstimates.processingOption($(this));
        });

    }

    static deleteDependencies($container, baseId) {
        $.each($container.find('.content-processing'), function (index, content) {
            if($(content).data('base-id') == baseId) {
                $(content).remove();
            }
        });
    }

    static missingDependencies(dependentIds, $container) {
        if (!Array.isArray(dependentIds)) {
            dependentIds = [dependentIds];
        }
        $container.find('select option:selected').each(function (index, element) {
            let selectedId = parseInt($(element).val());
            if (dependentIds.indexOf(selectedId) >= 0) {
                dependentIds.splice(dependentIds.indexOf(selectedId), 1)
            }
        })
        return dependentIds;
    }

    static replaceNotices(notices, $noticeContainer) {
        $noticeContainer.empty();
        if (!Array.isArray(notices)) {
            notices = [notices];
        }
        $.each(notices, function (index, notice) {
            let $cloneNotice = $('.notice-container-template').first().children().clone();
            $cloneNotice.find('.notice-text').text(notice);
            $noticeContainer.append($cloneNotice);
        });
    }

    /**
     * フォーム左の画像選択ボタンのURLを更新
     * @param $form
     */
    static updateImageUrl($form){
        $form.find('.link-choose-processing').each(function (index, element) {
            let modalUrl = $(element).attr('href').split('?')[0];
            $(element).attr('href', modalUrl + '?' + $form.serialize())
        });
    }

    static populatePosition($form, result, position){
        let positionResult = result[position];
        if(!positionResult) { return }
        for(let index = 0; index < $form.find('.field-processing-amount-' + position).length; index++) {
            if(positionResult.hasOwnProperty(index)) {
                let value = positionResult[index];
                $form.find('.field-processing-amount-' + position + '-' + index).val(value.count);
                $form.find('.field-processing-price-' + position + '-' + index).val(value.price);
                $form.find('.field-processing-total-' + position + '-' + index).val(value.total);
            }
            else {
                $form.find('.field-processing-amount-' + position + '-' + index).val('');
                $form.find('.field-processing-price-' + position + '-' + index).val('');
                $form.find('.field-processing-total-' + position + '-' + index).val('');
            }
        }
    }
    static populateYield($form, result){
        if(result.yield){
            $form.find('.field-yield-area').val(result.yield.area);
            $form.find('.field-yield-price').val(result.yield.price);
            $form.find('.field-yield-total').val(result.yield.total);
        }
        else {
            $form.find('.field-yield-area').val('');
            $form.find('.field-yield-price').val('');
            $form.find('.field-yield-total').val('');
        }

    }
    static populateSum($form, result){
        if(result.sum) {
            $form.find('.field-sum-cost_total').val(result.sum.cost_total);
            $form.find('.field-sum-yield_total').val(result.sum.yield_total); //商品合計（フォーム）
            $form.find('.field-sum-option_processing').val(result.sum.option_processing); //1間口あたりのオプション部材代（フォーム）
            $form.find('.field-sum-option_processing_total').val(result.sum.option_processing_total); //全間口のオプション部材合計（フォーム）
            $form.find('.field-sum-processing_by_sheet').val(result.sum.processing_by_sheet); //1間口あたりのシート加工代（フォーム）
            $form.find('.field-sum-processing_total').val(result.sum.processing_total); //全間口のシート加工合計（フォーム）
            $form.find('.field-sum-estimate-subtotal').val(result.sum.estimate_subtotal); //合計金額（フォーム）
            $form.find('.field-sum-weight_total').val(result.sum.weight_total); //重量（フォーム）

            $form.find('.label-sum-yield_total').text(Quote.shared.numberWithCommas(result.sum.yield_total)); //商品合計（右エリア表示）
            $form.find('.label-sum-option_processing_total').text(Quote.shared.numberWithCommas(result.sum.option_processing_total)); //オプション合計（右エリア表示）
            $form.find('.label-processing-price').text(Quote.shared.numberWithCommas(result.sum.option_processing)); //オプション小計（オプション詳細表示）
            $form.find('.label-sum-processing_total').text(Quote.shared.numberWithCommas(result.sum.processing_total)); //シート加工合計（右エリア表示）
            $form.find('.label-sum-estimate-subtotal').text(Quote.shared.numberWithCommas(result.sum.estimate_subtotal)); //合計金額（右エリア表示）
            $form.find('.label-sum-weight_total').text(Quote.shared.numberWithCommas(result.sum.weight_total)); //重量（右エリア表示）
        }
        else
        {
            $form.find('.field-sum-cost_total').val('');
            $form.find('.field-sum-yield_total').val('');
            $form.find('.field-sum-option_processing').val('');
            $form.find('.field-sum-option_processing_total').val('');
            $form.find('.field-sum-processing_by_sheet').val('');
            $form.find('.field-sum-processing_total').val('');
            $form.find('.field-sum-estimate-subtotal').val('');
            $form.find('.field-sum-weight_total').val('');

            $form.find('.label-sum-yield_total').text('-');
            $form.find('.label-sum-option_processing_total').text('-');
            $form.find('.label-processing-price').text('-');
            $form.find('.label-sum-processing_total').text('-');
            $form.find('.label-sum-estimate-subtotal').text('-');
            $form.find('.label-sum-weight_total').text('-');
        }
    }

    /**
     * 詳細画面のトータルの更新、右側画像の更新
     * @param $input
     */
    static processingOption($input){
        let $container = $input.closest('.option-container');
        UserEstimates.populateOptions($container);
        UserEstimates.populateImages($container);
    }

    /**
     * 選択中の製品・オプション画像を右側のエリアに表示する
     * @param result
     */
    static populateImages($container){
        let $form = $container.closest('.form-estimate');
        let processingType = $container.data('type');
        let $targetContainer = $form.find(`.processing-images-container .image-processing-${processingType}`);
        let $targetInner = $targetContainer.find('.carousel>.carousel-inner');
        $targetInner.empty();
        let numSelected = 0
        $container.find('.field-option').each(function (index, element) {
            let $amountField = $(element).find('.field-processing-amount');
            if ($amountField.prop('disabled')) return;
            let amount = parseInt($amountField.val());
            let imageUrl = $amountField.data('image-url');
            let processingName = $(element).find('.label-processing-name').text();
            if(amount >= 1) {
                let $itemTemplate = $targetContainer.first().find('.processing-images-template .carousel-item').clone();
                if (imageUrl) $itemTemplate.find('.item-image').attr('src', imageUrl);
                $itemTemplate.find('.processing-name').text(processingName);
                if (numSelected == 0) $itemTemplate.addClass('active');
                numSelected++;
                $targetContainer.find('.carousel-inner').append($itemTemplate);
            }
        });
        if (numSelected == 0)
            $targetContainer.hide();
        else
            $targetContainer.show();
    }

    /**
     * オプション詳細エリア更新
     * @param $form
     * @param result
     */
    static populateOptions($container){
        let subtotal = 0;
        let weightTotal = 0;
        //オプション小計の計算（レール、ランナー毎……）
        $container.find('.field-processing-amount').each(function (_index, element) {
            let $unit_price = $(element).siblings('.field-processing-unit_price');
            let $subtotal_price = $(element).siblings('.field-processing-subtotal_price');
            let subtotal_price = parseInt($unit_price.val()) * parseInt($(element).val() || 0);
            let weightSubtotal = parseFloat($(element).data('weight')) * parseInt($(element).val() || 0);
            $subtotal_price.val(subtotal_price);
            if (!$(element).prop('disabled')) {
                subtotal += subtotal_price;
                weightTotal += weightSubtotal;
            }
        });
        $container.find('.label-subtotal-price').text(Quote.shared.numberWithCommas(subtotal)).data('weight', weightTotal); //オプション小計

   }

    /**
     * オプション詳細エリア更新（デフォルト値）
     * @param $form
     * @param result
     */
    static populateDefaultOptions($form, result){
        $form.find('.option-container').each(function (index, element) {
            $(element).find('.field-options').empty();
            $(element).find('.label-subtotal-price').text('0');
        });
        $form.find('.label-processing-price').text('-');
        if(!result.processings)
            return false;
        $.each(result.processings, function(key, processings) {
            //console.log(key, processings);
            let $container = $form.find(`.field-${key}-container`);
            let total = 0;
            $.each(processings, function(index, processing) {
                let $field = $form.find(`.form-processing-template`).children().clone(true,true);
                UserEstimates.populateOptionField($field, key, processing);
                let subtotal_price = parseInt(processing.amount) * parseInt(processing.unit_price);
                $field.find('.field-processing-subtotal_price').val(subtotal_price);
                total += subtotal_price;
                $container.append($field);
            });
            //画像表示
            UserEstimates.populateImages($container.closest('.option-container'));
            $container.closest('.option-container').find('.label-subtotal-price').text(Quote.shared.numberWithCommas(total));
        });
        //活性・不活性を更新
        $('.form-estimate .add-processing-group:checked').trigger('change', [true]);
        return true;
    }

    static populateOptionField($field, key, processing, prefix = 'form[estimate_processing]'){
        $field.find('.label-processing-name').text(processing.name);
        $field.find('.label-processing-unit').text(processing.unit);
        $field.find('.field-processing-amount').attr('name', `${prefix}[${key}][][amount]`);
        $field.find('.field-processing-id').attr('name', `${prefix}[${key}][][processing_id]`);
        $field.find('.field-processing-unit_price').attr('name', `${prefix}[${key}][][unit_price]`);
        $field.find('.field-processing-subtotal_price').attr('name', `${prefix}[${key}][][subtotal_price]`);
        $field.find('.field-processing-amount').val(processing.amount);
        $field.find('.field-processing-amount').data('image-url', processing.image_url); // 画像表示URL
        $field.find('.field-processing-amount').data('weight', processing.weight); // 重さ
        $field.find('.field-processing-amount').data('unselected', processing.unselected); // 未選択
        $field.find('.field-processing-amount').data('default-amount', processing.default_amount); // デフォルト値
        $field.find('.field-processing-id').val(processing.processing_id);
        $field.find('.field-processing-unit_price').val(processing.unit_price);
        $field.find('.radio-processing').data('pair-ids', processing.pair_ids);
        let $noticeContainer = $field.find('.notice-container');
        UserEstimates.replaceNotices(processing.notices, $noticeContainer); // 注意書き
    }

    /**
     * お勧めハイライト機能
     * @param $form
     * @param result
     */
    static populateRecommends($form, result){
        $form.find('.item-recommendable').each(function (index, element) {
            $(element).removeClass('recommend');
        });
        $('.label-not_recommended').hide();
        if(!result.recommends)
            return;
        if(!result.has_recommended_product)
            $('.label-not_recommended').show();
        $.each(result.recommends, function(index, recommend_info) {
            $form.find('input[name="' + recommend_info.input_name + '"]').each(function (index, element) {
                if(recommend_info.values.includes($(element).val())) {
                    $(element).closest('.item-recommendable').addClass('recommend');
                }
            });
            UserEstimates.updateSelectedRadio($form, recommend_info);
        });
    }
    static updateSelectedRadio($form, recommend_info){
        if (recommend_info.values.length == 0) return;
        $form.find('input[name="' + recommend_info.input_name + '"]').val(recommend_info.values);
        // 商品選択が変わる場合は画像の更新が必要
        if (recommend_info.name == 'product_id') {
            let $radio_product = $form.find('input[name="' + recommend_info.input_name + '"]:checked');
            UserEstimates.updateProductImage($radio_product);
            UserEstimates.updateProductId($radio_product);
        }
    }
    /**
     * 規格・材質デフォルト選択 ＆ 材質選択フォーム表示切り替え
     * @param $form
     * @param result
     */
    static populateSpecification($form, result){
        // 規格選択
        if(result.specification_id) {
            $form.find('input[value="' + result.specification_id + '"]').prop('checked', true);
        } else {
            $form.find('.field-specification').prop('checked', false);
        }
        // 材質選択
//        if(result.material) {
//            $form.find('input[value="' + result.material + '"]').prop('checked', true);
//        } else {
//            $form.find('.field-material').prop('checked', false);
//        }
        // 材質選択フォーム表示切り替え
        if(result.show_material) {
            $form.find('.field-material').prop('disabled', false);
            $form.find('.form-material').show();
        } else {
            $form.find('.field-material').prop('disabled', true);
            $form.find('.form-material').hide();
        }
        //シート加工タブの更新
        let $checkedMaterial = $('.form-estimate .field-material:checked');
        $checkedMaterial.first().trigger('change');
    }

    /**
     * 交通費計算
     */
    static calc_transportation() {
        $('.form-estimate').on('change', '.transportation-trigger', function(e) {
            let $select = $(e.target);
            let prefectureId = $select.val();
            let weight = $select.data('weight');
            let $form = $('.form-estimate');
            let cartIds = $form.data('carts');
            let calc_url = "/api/estimates/calc_transportation?cart_ids={cart_ids}&prefecture_id={prefecture_id}&weight={weight}"
            $.ajax({
                url: calc_url.replace('{cart_ids}', cartIds).replace('{prefecture_id}', prefectureId).replace('{weight}', weight),
                type: 'post'
            }).done(function(result, textStatus, xhr) {
                console.log(result);
                $form.find('.transportation-error-container').empty().hide();
                if(!result){
                    return;
                } else if(result.error) {
                    Quote.shared.show_error(result.error);
                } else {
                    updateTransportation($form, result);
                    populateTotal($form);
                }
            }).fail(function(e) {
                Quote.shared.show_error(e.statusText);
            });
            return false;
        })
        function updateTransportation($form, result){
            if(result.price == null) {
                $form.find('.label-sum-transportation').text('-');
            }
            else{
                $form.find('.label-sum-transportation').text(Quote.shared.numberWithCommas(result.price));
            }
            if(result.errors) {
                $form.find('.transportation-error-container');
                $.each(result.errors, function(index, error_message) {
                    let $error_message = $('<small>').text(error_message);
                    $form.find('.transportation-error-container').show().append($error_message);
                });
            }
        }
        function populateTotal($form){
            let subtotal = Quote.shared.revertNumberWithCommas($form.find('.label-sum-estimate-subtotal').first().text());
            let transportation = Quote.shared.revertNumberWithCommas($form.find('.label-sum-transportation').first().text());
            let tax = Math.floor((subtotal + transportation) * 0.1);
            let total = subtotal + transportation + tax;
            $form.find('.label-sum-estimate-tax').text(Quote.shared.numberWithCommas(tax));
            $form.find('.label-sum-estimate-total').text(Quote.shared.numberWithCommas(total));
        }
    }

    /**
     * 販売用見積り作成
     */
    static customerEstimate(){
        let $form = $('.form-customer-estimate');
        let $fieldCustomerRate = $form.find('.field-estimate-customer_rate');
        // 粗利率変更
        $fieldCustomerRate.find('input').on('change keyup', function(event) {
            let customerRate = Number($(this).val());
            let optionTotal = Number($form.find('.field-sum-option').val() || 0);
            // エラー文字をクリア
            $form.find('.error-customer-rate').text('');
            $fieldCustomerRate.removeClass('error');
            // 入力した値が1以上の整数でないならエラー表示
            if (!(Number.isInteger(customerRate) && customerRate > 0)) {
                $form.find('.error-customer-rate').text("1以上の整数を入力してください。");
                $fieldCustomerRate.addClass('error');
                return;
            }
            calcCustomerTotal(customerRate, optionTotal);
            return false;
        });
        function calcCustomerTotal(customerRate, optionTotal){
            let estimateId = $form.data('estimate');
            let calc_url = "/api/estimates/calc_customer_estimate?customer_rate={customer_rate}&estimate_id={estimate_id}&option_total={option_total}"
            $.ajax({
                url: calc_url.replace('{estimate_id}', estimateId).replace('{customer_rate}', customerRate).replace('{option_total}', optionTotal),
                type: 'post',
                data: $form.serialize(),
                success: function(result) {
                    console.log(result);
                    if(result.error) {
                        Quote.shared.show_error(result.error);
                    }
                    populate(result);
                }
            })
            return false;
        }
        function populate(result){
            $form.find('.label-new-sum-estimate-subtotal').text(Quote.shared.numberWithCommas(result.estimate.subtotal_price));
            $form.find('.label-new-sum-estimate-tax').text(Quote.shared.numberWithCommas(result.estimate.tax_price));
            $form.find('.label-new-sum-estimate-total').text(Quote.shared.numberWithCommas(result.estimate.total_price));
            $form.find('.label-new-sum-estimate-profit').text(Quote.shared.numberWithCommas(result.estimate.profit_price));
        }

        // 金額調整
        $form.on('click', '.button-option-add', function() {
            cloneOptionForm($form.find('.content-option'));
            return false;
        });
        $form.on('click', '.button-option-delete', function() {
            let $field = $(this).closest('.content-option');
            $field.remove();
            $('.option-trigger').first().trigger('change');
            return false;
        });
        $form.on('change', '.option-trigger', function() {
            let optionTotal = 0;
            $form.find('.option-trigger:enabled').each(function(index, element){
                let optionValue = parseInt($(element).val());
                optionTotal += optionValue;
            })
            $form.find('.field-sum-option').val(optionTotal);
            let customerRate = Number($fieldCustomerRate.find('input').val());
            calcCustomerTotal(customerRate, optionTotal);
        });
        function cloneOptionForm($content) {
            let nextIndex = $content.find('.field-container>div').length
            let htmlText = $content.find('.field-template').html().replace(/9999/g, nextIndex.toString()).trim();
            let $clone = $($.parseHTML(htmlText)[0]);
            $clone.find('input, textarea').prop('disabled', false);
            $content.find('.field-container').append($clone);
        }
    }
}
module.exports = UserEstimates
