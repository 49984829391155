import consumer from "./consumer"

const inquiryChannel = consumer.subscriptions.create("UserInquiryChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const UserShared = require('../../user/shared.js.erb');
    UserShared.inquiry_received(data);
  }

});


