class UserShared {
    static load() {
        $(document).on('slid.bs.carousel', '.carousel-container>.carousel', function(event) {
            let $container = $(this).closest('.carousel-container');
            let slideNumber = $container.find('.carousel-item.active').data('slide-number');
            let totalCount = $container.data('image-count');
            let page = parseInt(slideNumber/4);
            console.log(totalCount, slideNumber, page);
            $container.find('.thumbnail-list.active').removeClass('active');
            $container.find('.carousel-indicators').carousel(page);
            $container.find('.thumb.active').removeClass('active');
            $container.find('.thumb[data-slide-to=' + slideNumber + ']').addClass('active');
        });
    }
    static carousel() {

        /*$('.carousel').on 'slid.bs.carousel', () ->
            slideNumber = $('.carousel-item.active').data('slide-number')
        attachmentLength = $('.carousel-item.active').data('attchment-length')
        $('.attachment-number').text("#{slideNumber + 1}/#{attachmentLength}")
        $('.thumb.active-thumb').removeClass('active-thumb')
        activeThumb = $(".thumb[data-slide-to='#{slideNumber}']").addClass('active-thumb')*/
    }
    /* 見積ステータス更新 */
    static estimate_received(data) {
        let estimateId = data.estimate_id;
        let isTentative = data.is_tentative;
        let $icon_holder = $('.table-estimates tr[data-estimate_id="' + estimateId + '"] .tentative-icon-holder');
        $icon_holder.find('.estimate-status-badge').hide();
        $icon_holder.find('.estimate-status-is_tentative-' + isTentative).show();
        let $pdf_icon_holder = $('.pdf-link-container[data-estimate_id="' + estimateId + '"]');
        $pdf_icon_holder.find('.estimate-pdf-icon').hide();
        if(isTentative == 'true') {
            $pdf_icon_holder.find('.estimate-float').show();
        } else {
            $pdf_icon_holder.find('.estimate-fixed').show();
        }
    }
    static message_received(data) {
        let inquiryId = data.inquiry_id;
        let userId = data.user_id;
        let content = data.content;
        console.log("UserShared:message_received:" + userId + ":" + inquiryId + ":" + content);
        if ($('form.form-messages-refresh').length > 0) {
            Rails.fire($('form.form-messages-refresh')[0], 'submit');
        }
    }
    static inquiry_received(data) {
        let inquiryId = data.inquiry_id;
        let openStatus = data.open_status;
        console.log("UserShared:inquiry_received:" + inquiryId + ":" + openStatus);
        let $icon_holder = $('tr[data-inquiry_id=' + inquiryId + ']>td span.td-open-status');
        $icon_holder.find('.inquiry-status-badge').hide();
        $icon_holder.find('.inquiry-status-' + openStatus).show();
        let $element = $('.span-unread-count .total-unread-count');
        let unreadIds = $element.data('inquiries');
        let currentCount = parseInt($element.text());
        if (openStatus == 'read' && unreadIds.indexOf(inquiryId) >= 0) {
            currentCount--;
            unreadIds = unreadIds.filter(n => n !== inquiryId);
        }
        if (openStatus == 'unread_user' && unreadIds.indexOf(inquiryId) < 0) {
            currentCount++;
            unreadIds.push(inquiryId);
        }
        if(currentCount == 0) {
            $element.closest('.badge').hide();
        }
        else {
            $element.closest('.badge').show();
        }
        $element.text(currentCount);
        $element.data('inquiries', unreadIds);
    }

    static update_count(data) {
        if ($('table.table-inquiries').length > 0)
        {
            let inquiryId = data.inquiry_id;
            let $open_status_td = $('table.table-inquiries tr[data-inquiry_id="' + inquiryId + '"] td.td-open-status');
            $open_status_td.html($('<span class="mr-1 badge badge-warning">未読</span>'))
        }
        $('.sidebar-nav li.nav-item .span-unread-count').html($('<span class="badge badge-warning badge-pill"><i class="fas fa-comment fa-fw"></i><span class="total-unread-count">' + data.unread_count + '</span></span>'))
    }
    static scroll_top() {
        var pagetop = $('#page_top');
        pagetop.hide();
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {  //100pxスクロールしたら表示
                pagetop.fadeIn();
            } else {
                pagetop.fadeOut();
            }
        });
        pagetop.click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 500); //0.5秒かけてトップへ移動
            return false;
        });
    }
}
module.exports = UserShared