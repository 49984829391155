class Shared {
    static load() {
        console.log('load');
        // 戻るボタンでデータ再取得
        $(window).on("popstate", function (event) {
            window.location.reload();
        })

        // 多重モーダルを許可
        $(document).on('hidden.bs.modal', () => {
            if($('.modal').is(':visible')) {
                $(document.body).addClass('modal-open');
            }
        });

        $(document).on('click', '.button-spin', (event) => {
            Quote.shared.init_spinner($(event.currentTarget));
            return true;
        });

        $(document).on('hidden.bs.modal', '.modal', () => {
            Quote.shared.end_spinner();
        });

        $(document).on('turbolinks:load', (event) => {
            $('.sidebar').sidebar();
            $('.aside-menu')['aside-menu']();
            console.log('turbolinks:load');
            lightbox.init();
            $('[data-toggle="popover"]').popover();
        });

        $(document).on('click', '.stop-propagation', (event) => {
            event.stopPropagation();
            return true;
        });

        $(document).on('click', '.link-box', (event) => {
            const href = $(event.currentTarget).data('href');
            location.href = href;
        });

        $(document).on('keypress', 'input:not(.allow-submit), select:not(.allow-submit)', (event) => {
            return event.which != 13
        });

        $(document).on('click', '.link-filter-clear', function() {
            let $container = $(this).closest('.search-form-inputs');
            let $form = $container.find('form');
            let $field = $container.find('.field-query');
            let $button = $container.find('.button-search');
            $field.val('');
            $button.trigger('click');
            return false;
        });


        /*
         * 右パネルの「閉じる」ボタン
         */
        $(document).on('click', '.button-close-right', (event) => {
            $('body').removeClass('aside-menu-show');
            $('span.open-icon>.link-close').hide();
            $('span.open-icon>.link-open').show();
            return false;
        })

        /*
         * selectで「その他」を選んだ時に隣のtext_fieldがアクティブになる
         */
        $(document).on('change', '.combobox-select', function() {
            let $field = $(this).closest('.combobox-container');
            let $textfield = $field.find('.combobox-text');
            $textfield.prop('disabled', $(this).val() != '0');
        });

        /*
         * checkboxを選択した時に関連するfieldがアクティブになる
         */
        $(document).on('change', '.check-group-checkbox', function() {
            let $field = $(this).closest('.check-group-container');
            let $textfield = $field.find('.check-group-field');
            $textfield.prop('disabled', !$(this).prop('checked'));
        });

        dataConfirmModal.setDefaults({
            title: '本当に削除しますか？',
            commit: '削除',
            cancel: 'キャンセル',
            commitClass: 'btn btn-lg btn-danger',
            cancelClass: 'btn btn-lg btn-default'
        });

        /**
         * pagination, sortはremoteで実行するが、URLにパラメータを記載する
         */
        $(document).on("click", ".pagination .page-item a", (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
            let historyURL = new URL(window.location);
            let historyParams = historyURL.searchParams;
            let $table = $($(event.target).closest('.pagination-link-sort').data("table"));
            let modelName = $table.data("model");
            Quote.shared.setPaginationParams(historyParams, modelName, $(event.target));
            //
            console.log(historyURL.toString());
            history.pushState(null, null, historyURL.toString());
            let sortURL = new URL(window.location.origin + $table.data("sort-path"));
            Quote.shared.buildQuery(sortURL, historyParams);
            $.ajax({
                dataType: 'script',
                url: sortURL.toString(),
                success: ()  => {}
            })
            return false;
        });

        // 通常のタブ
        $(document).on('click', '.nav .tab-link-remote', function(event) {
            console.log('paginationSort is undefined');
            let url = new URL(window.location.origin + $(event.target).attr("href"));
            //console.log(url.toString());
            history.pushState(null, null, url.toString());
            return true;
        });


        // tabを使ったフィルター機能（複数画面未対応）
        $(document).on('click', '.nav .tab-filter', function(event) {
            console.log('paginationSort is undefined');
            let url = new URL(window.location.origin + $(event.target).attr("href"));
            let urlParams = url.searchParams;
            let historyURL = new URL(window.location);
            let historyParams = historyURL.searchParams;
            let $table = $($(event.target).closest(".nav-container").data("table"));
            let modelName = $table.data("model");
            historyParams.forEach(function(value, key) {
                if(key.indexOf(`${modelName}[`) < 0){
                    urlParams.append(key, value);
                }
            });
            history.pushState(null, null, url.toString());
            return true;
        });

//       テーブルヘッダーを使ったテーブルソート機能
        $(document).on("click", ".sortable-table .sort-button", (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
            let $column = $(event.target).closest(".sort-column");
            let $table = $(event.target).closest(".sortable-table");
            let modelName = $table.data("model");
            let sortURL = new URL(window.location.origin + $table.data("sort-path"));
            let historyURL = new URL(window.location);
            let historyParams = historyURL.searchParams;
            Quote.shared.setSortParams(historyParams, modelName, $column);
            Quote.shared.buildQuery(sortURL, historyParams);
            history.pushState(null, null, historyURL.toString());
            $.ajax({
                dataType: 'script',
                url: sortURL.toString(),
                success: ()  => {}
            })
            return false;
        });

//       複数検索でのURL作成
        $(document).on("click", ".multiple-search-button", (event) => {
            let $searchInputs = $(event.target).closest('.multiple-search-form').find('.search-inputs');
            let $filterPath = $(event.target).closest('.multiple-search-form').data("filter-path");
            let historyURL = new URL(window.location.origin + $filterPath);
            $searchInputs.each(function(index, input) {
                historyURL.searchParams.set($(input).attr('name'), $(input).val());
            });
            historyURL.searchParams.set('only_fixed', $('.only-fixed-checkbox').prop("checked"));
            console.log(historyURL.toString());
            history.pushState(null, null, historyURL.toString());
            $.ajax({
                dataType: 'script',
                url: historyURL.toString(),
                success: () => {}
            })
            return false;
        });


//        検索機能でのURL作成
        $(document).on("click", ".search-form-inputs .button-search", (event) => {
            event.stopImmediatePropagation();
            event.preventDefault();
            let $inputText = $(event.target).closest('.search-form-inputs').find('.field-query');
            let inputValue = $inputText.val();
            let $table = $($(event.target).closest('.search-form-inputs').data("table"));
            let modelName = $table.data("model");
            let sortURL = new URL(window.location.origin + $table.data("sort-path"));
            let historyURL = new URL(window.location);
            let historyParams = historyURL.searchParams;
            Quote.shared.setSearchParams(historyParams, modelName, inputValue);
            Quote.shared.buildQuery(sortURL, historyParams);
            history.pushState(null, null, historyURL.toString());
            //console.log("check" + url.toString());
            $.ajax({
                dataType: 'script',
                url: sortURL.toString(),
                success: ()  => {}
            })
            return false;
        });
    }

    /**
     * 行の追加・削除が可能なテーブル
     * @param $table
     * @param $button_add
     */
    static dynamic_table($table, $button_add) {
        $table.on("click", ".button-delete", (event) => {
            $(event.target).closest("tr").remove();
            return false;
        });
        if(!$button_add)
            return;
        $button_add.on("click", (event) => {
            let $row = $table.find('.tbody-template > tr').clone();
            $table.find('.tbody-main').append($row);
            return false;
        });
    }

    static other_unit_type($table) {
        $table.on("change", ".select-processing-other", (event) => {
            let unitType = $(event.target).find('option:selected').data('unit-type') || '';
            $(event.target).closest('.processing-other-form-row').find('.processing-unit-type').text(unitType);
        });
    }

    /**
     * スピナー登録
     * @param $target
     */
    static init_spinner($target) {
        console.log('init_spinner click');
        //let $button = $(this)
        $target.find('.fa-spin').show();
        $target.find('.pre-spin').hide();
        setTimeout(() => {
            $target.addClass('disabled').prop('disabled', true);
        },10);
    }

    /**
     * スピナー終了
     * @param $base
     */
    static end_spinner($base) {
        console.log('end_spinner');
        if ($('.button-spin').length > 0) {
            //button-spin-for-modal
            console.log('end_spinner start');
            let $button;
            if($base) {
                $button = $base.find('.button-spin');
            }
            else {
                $button = $('.button-spin');
            }
            if ($button.find('.fa-spin').is(':visible')) {
                $button.find('.fa-spin').hide();
                $button.find('.pre-spin').show();
                $button.removeClass('disabled').prop('disabled', false);
            }
        }
    }

    /**
     * 全エラーラベルクリア
     * @param $base
     */
    static clean_validation_errors($base) {
        $base.find('.ata-field').removeClass('error')
        $base.find('.error-message').text('');
    }

    /**
     * エラーラベル表示
     * @param $target
     * @param message
     */
    static show_validation_errors($target, message) {
        $target.addClass('error')
        $target.find('.error-message').append($('<div>').append($('<span>').text(message)));
    }

    static hide_validation_errors($target) {
        $target.removeClass('error')
        $target.find('.error-message').text('');
    }

    static hide_flash_message() {
        setTimeout(() => {
            $('#flash-message-container').hide();
        },2000);
    }

    static show_flash_message() {
        $('#flash-message-container').slideDown(1000, this.hide_flash_message)
    }

    static show_error(message) {
        $('#flash-message-container').html($('<div>').addClass('alert alert-danger').text(message)[0]);
        Quote.shared.show_flash_message();
    }

    static numberWithCommas(x) {
        if(x == null)
            return '-'
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    /**
     * 不正なデータは「0」を返す
     * @param x
     * @returns {number}
     */
    static revertNumberWithCommas(x) {
        if(x == null)
            return 0
        return parseInt(x.toString().replace(",", "")) || 0;
    }

    static searchAddress(prefix) {
        $('.button-zip-to-address').on('click', function() {
            if($(this).closest('.form-address').find('textarea[name="' +  prefix + '[address]' + '"]').val().length > 0) {
                if(confirm("住所を上書きしてよろしいですか？")){
                    AjaxZip3.zip2addr(prefix + '[zip_code]', '', prefix + '[prefecture_id]', prefix + '[address]');
                }
            }
            else {
                AjaxZip3.zip2addr(prefix + '[zip_code]', '', prefix + '[prefecture_id]', prefix + '[address]');
            }
            AjaxZip3.onSuccess = function() {
                $('.address-address').focus();
                $('.transportation-trigger').trigger('change');
            };
            AjaxZip3.onFailure = function() {
                alert("郵便番号に該当する住所が見つかりません");
            };
            return false;
        });
    }

    static uuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static setSortParams(params, modelName, $column) {
        params.set(`${modelName}[sort_column]`, $column.data("column"));
        params.set(`${modelName}[order_type]`, $column.data("order"));
    }

    static setPaginationParams(params, modelName, $link) {
        let paginationURL = new URL(window.location.origin + $link.attr('href'));
        let paginationParams = paginationURL.searchParams;
        params.set(`${modelName}[page]`, paginationParams.get(`${modelName}[page]`));
    }

    static setSearchParams(params, modelName, $inputValue) {
        params.set(`${modelName}[search_word]`, $inputValue);
//        ページ数だけリフレッシュ
        params.set(`${modelName}[page]`, '');
    }

    static buildQuery(url, params) {
        for (let [key, value] of params) {
            console.log(key, value);
            url.searchParams.append(key, value);
        }
    }

    static setInquiryParams(inquryId) {
        let historyURL = new URL(window.location);
        let historyParams = historyURL.searchParams;
        historyParams.delete('inquiry_id');
        if(inquryId) {
            historyParams.set('inquiry_id', inquryId);
        }
        history.pushState(null, null, historyURL.toString());
    }

    static enableToggleLink() {
        // toggle nav-item上のラベルをリンク可能にする
        $('.nav-link-enabled').on('click', (event) => {
            let url = $(event.currentTarget).data('url');
            location.href = url;
        });
    }
}
module.exports = Shared
