// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels/user"

import "bootstrap"
import "./coreui-pro/coreui.min.js"
import "./coreui-pro/coreui-utilities.min.js"
import '@fortawesome/fontawesome-free/js/all'
import "./masonry/masonry.pkgd.min.js"
import "./masonry/imagesloaded.pkgd.min.js"

Rails.start()
global.Rails = Rails;
Turbolinks.start()
ActiveStorage.start()

// js.erb上で"$"を使用可能にする
window.$ = window.jQuery = jQuery;
window.lightbox = require('lightbox2');

const Shared = require('./shared.js.erb');
const SharedEstimates = require('../shared/estimates.js.erb');
const UserShared = require('../user/shared.js.erb');
const UserEstimates = require('../user/estimates.js.erb');
const UserInstructions = require('../user/instructions.js');
const UserInquiries = require('../user/inquiries.js.erb');
window.Quote = {
    shared: Shared,
    shared_estimates: SharedEstimates,
    user_shared: UserShared,
    user_estimates: UserEstimates,
    user_instructions: UserInstructions,
    user_inquiries: UserInquiries
};

require('data-confirm-modal');

Quote.shared.load();
Quote.user_shared.load();
