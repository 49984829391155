import consumer from "./consumer"

const estimateChannel = consumer.subscriptions.create("StaffEstimateChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    console.log('count received:' + data);
    const UserShared = require('../../user/shared.js.erb');
    UserShared.estimate_received(data);
  }

});

