class SharedEstimates {

    /**
     * 間口・製品サイズバリデーション　共通
     * @param $field
     */
    static size_check($field){
        let $target = $field.closest('.ata-field');
        let min = $field.data('min');
        let max = $field.data('max');
        let message = "{min}mm〜{max}mmの値を指定してください。"
        Quote.shared.hide_validation_errors($target);
        if(!$field.val()) {
            return;
        }
        if($field.val() < min || $field.val() > max) {
            Quote.shared.show_validation_errors($target, message.replace('{min}', min).replace('{max}', max));
        }
    }

    /**
     * 製品情報をフィールドに記入　共通
     * @param $form
     * @param result
     */
    static populateProductSize($form, result){
        if(result.product_size) {
            $form.find('.field-width').val(result.product_size.width);
            $form.find('.field-height').val(result.product_size.height);
            $form.find('.label-width').text(result.product_size.width);
            $form.find('.label-height').text(result.product_size.height);
        }
        else {
            $form.find('.field-width').val('');
            $form.find('.field-height').val('');
            $form.find('.label-width').text('-');
            $form.find('.label-height').text('-');
        }
        SharedEstimates.size_check($form.find('.field-width'));
        SharedEstimates.size_check($form.find('.field-height'));
    }
    /**
     * オプション選択関連　共通
     */
    static processing_options() {
        /**
         * オプション画像表示
         */
        $('.link-show-processing').on('click', function() {
            let $container = $(this).closest('.option-group');
            let id_params = $container.find('.field-processing-id').map(function (_index, element) {
                return `processing_ids[]=${$(element).val()}`;
            }).toArray();
            $.ajax({
                url: $(this).attr('href'),
                type: 'post',
                data: id_params.join('&'),
            }).done(function(result, textStatus, xhr) {
                // Nothing to do
            }).fail(function(e) {
                Quote.shared.show_error(e.statusText);
            });
            return false;
        });
        /**
         * オプション詳細ボタンのアイコン切替（上・下）
         */
        $('#option-details-container').on('shown.bs.collapse', function() {
            $('.button-option-details .icon-up').show();
            $('.button-option-details .icon-down').hide();
        });
        $('#option-details-container').on('hidden.bs.collapse', function() {
            $('.button-option-details .icon-up').hide();
            $('.button-option-details .icon-down').show();
        });
    }

    /**
     * ポール変更でランナー数変更
     */
    static calcRunnerIncrement(addPole) {
        let doorType = $('input[name="form[estimate_item][door_type]"]:checked').val();
        let edgeType = $('input[name="form[estimate_item][edge_type]"]:checked').val();
        let runnerIncrement = 0;
         //選択にした場合は開閉ポールはデフォルト
        if (addPole) {
            return runnerIncrement;
        }
        else { // ポールが消えた分を加算
            if(doorType == 'double') {
                runnerIncrement += 2;
                if(edgeType == 'door')
                    runnerIncrement += 2;
            } else {
                runnerIncrement += 1;
                if(edgeType == 'door')
                    runnerIncrement += 1;
            }
            return runnerIncrement;
        }
    }

    static calcRunnerByPole(fireCalc) {
        let selected = $('.form-estimate .add-pole-processing-group:checked').val() === 'true';
        let increment = Quote.shared_estimates.calcRunnerIncrement(selected);
        let $container = $('.option-container[data-type="runner"]');
        let $runnerField;
        let $hangerField;
        $container.find('input[name="form[estimate_processing][runner][][amount]"]').each(function (index, element) {
            if($(element).data('default-amount') > 0) {
                $runnerField = $(element);
                let runnerDisabled = $runnerField.prop('disabled');
                let defaultRunnerAmount = $runnerField.data('default-amount');
                let runnerAmount = defaultRunnerAmount + increment;
                if (!runnerDisabled){
                    $runnerField.val(runnerAmount);
                }
            }
        });
        $('.option-container-aurora').find('input[name="form_blank[aurora_hanger][][amount]"]').each(function (index, element) {
            if(!$(element).prop('disabled')) {
                $hangerField = $(element);
                let defaultHangerAmount = $hangerField.data('default-amount');
                let hangerAmount = defaultHangerAmount + increment;
                $hangerField.val(hangerAmount);
            }
        });
        if(fireCalc) {
            if($hangerField)
                $hangerField.trigger('change');
            if($runnerField)
                $runnerField.trigger('change');
        }
    }

    static adjustRunnerByPole() {
        $('.form-estimate').on('change', '.add-pole-processing-group', function(e, skipCalc) {
            Quote.shared_estimates.calcRunnerByPole(!skipCalc);
        });
    }


}
module.exports = SharedEstimates
