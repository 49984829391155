class UserInquiries {
    static filter() {
        $('.btn-show-filter').on('click', function() {
            console.log($(this).hasClass('collapsed'));
            let historyURL = new URL(window.location);
            historyURL.searchParams.set('show_filter', $(this).hasClass('collapsed'));
            history.pushState(null, null, historyURL.toString());
            $('#form-inquiry-filter input.hidden-show-filter').val($(this).hasClass('collapsed'));
        });
        $('.btn-search-clear').on('click', function() {
            $('#form-inquiry-filter .search-inputs').val('');
        });
    }
}
module.exports = UserInquiries