class UserInstructions {
    static index() {
        let urlHash = location.hash;
        if (urlHash.length) {
            $(`.instruction-container ${urlHash} .collapse-card-body`).addClass('show');
            $(`.instruction-container ${urlHash} .fa-arrow-circle-up`).addClass('d-none');
            $(`.instruction-container ${urlHash} .fa-arrow-circle-down`).removeClass('d-none');
            let position = $(`.instruction-container ${urlHash} a.anchor-60`).offset().top - 80;
            $('body,html').animate({scrollTop:position}, 400);
        }

        $('.collapse-card-body').on('show.bs.collapse', function () {
            let parents = $(this).parents('.card-instruction')
            parents.find('.fa-arrow-circle-down').addClass('d-none');
            parents.find('.fa-arrow-circle-up').removeClass('d-none');
        })

        $('.collapse-card-body').on('hide.bs.collapse', function () {
            let parents = $(this).parents('.card-instruction')
            parents.find('.fa-arrow-circle-up').addClass('d-none');
            parents.find('.fa-arrow-circle-down').removeClass('d-none');
        })
    }
}
module.exports = UserInstructions